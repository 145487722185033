import AddressTable from "./AddressTable";
import useServer from "../../../../hooks/useServer";
import useGlobalAlert from "../../../../context/GlobalAlert/useGlobalAlert";
import usePage from "../../../../usePage/usePage";
import Card from "../../../../components/Card";
import Text from "../../../../components/Text";
import TextButton from "../../../../components/TextButton";
import Content from "../../../../components/Content";

const errors = {
  address_company: { minLength: [1, "Company is required"] },
  address_firstName: { minLength: [1, "First Name is required"] },
  address_lastName: { minLength: [1, "Last Name is required"] },
};

function AddressBook() {
  const { state, set } = usePage({}, errors);
  const {
    address_customerId,
    address_profileName,
    address_company,
    address_firstName,
    address_lastName,
    address_website,
    address_email,
    address_phone,
    address_id,
    address_ship_address1,
    address_ship_address2,
    address_ship_address3,
    address_ship_city,
    address_ship_state,
    address_ship_country,
    address_ship_postalCode,
    address_bill_address1,
    address_bill_address2,
    address_bill_address3,
    address_bill_city,
    address_bill_state,
    address_bill_country,
    address_bill_postalCode,
  } = state;
  const { post } = useServer();
  const { setAlert } = useGlobalAlert();

  const on_edit = () => {
    set("mode", "edit");
    set("modal_open", true);
    set("modal_address_id", address_id);
    set("modal_customerId", address_customerId);
    set("modal_profileName", address_profileName);
    set("modal_company", address_company);
    set("modal_firstName", address_firstName);
    set("modal_lastName", address_lastName);
    set("modal_website", address_website);
    set("modal_email", address_email);
    set("modal_phone", address_phone);
    set("modal_ship_address1", address_ship_address1);
    set("modal_ship_address2", address_ship_address2);
    set("modal_ship_address3", address_ship_address3);
    set("modal_ship_city", address_ship_city);
    set("modal_ship_state", address_ship_state);
    set("modal_ship_country", address_ship_country);
    set("modal_ship_postalCode", address_ship_postalCode);
    set("modal_bill_address1", address_bill_address1);
    set("modal_bill_address2", address_bill_address2);
    set("modal_bill_address3", address_bill_address3);
    set("modal_bill_city", address_bill_city);
    set("modal_bill_state", address_bill_state);
    set("modal_bill_country", address_bill_country);
    set("modal_bill_postalCode", address_bill_postalCode);
  };

  return (
    <>
      {address_customerId === "" ? (
        <h4> Load Customer</h4>
      ) : (
        <Card
          regions={`
      '. header header header header .'
      '. profile company contact edit .'
      '. email web phone phone .'
      '. address address address address .' 
      '. city country state postalCode .'
      '. table table table table .'`}
          style={`width: 100%`}
        >
          <Text
            region="header"
            title="Information"
            style={`background-color:var(--tertiary); width:100%; padding: 0.5rem; border-radius: var(--border-radius);`}
            element="h3"
          />
          <Content region="profile">
            <Text
              title="Profile:"
              element="h4"
              style={`margin-right: var(--margin)`}
            />
            <Text title={address_profileName} />
          </Content>
          <Content region="company">
            <Text
              title="Company:"
              element="h4"
              style={`margin-right: var(--margin)`}
            />
            <Text title={address_company} />
          </Content>
          <Content region="contact">
            <Text
              title="Contact:"
              element="h4"
              style={`margin-right: var(--margin)`}
            />
            <Text title={`${address_firstName} ${address_lastName}`} />
          </Content>
          <TextButton region="edit" title="Edit" onClick={on_edit} />
          <Content region="email">
            <Text
              title="Email:"
              element="h4"
              style={`margin-right: var(--margin)`}
            />
            <Text title={address_email} />
          </Content>
          <Content region="web">
            <Text
              title="Website:"
              element="h4"
              style={`margin-right: var(--margin)`}
            />
            <TextButton title={address_website} onClick={() => {}} />
          </Content>
          <Content region="phone">
            <Text
              title="Phone:"
              element="h4"
              style={`margin-right: var(--margin)`}
            />
            <Text title={address_phone} />
          </Content>
          <Content region="address">
            <Text
              title="Address:"
              element="h4"
              style={`margin-right: var(--margin)`}
            />
            <Text
              title={`${address_ship_address1} ${address_ship_address2} ${address_ship_address3}`}
            />
          </Content>
          <Content region="city">
            <Text
              title="City:"
              element="h4"
              style={`margin-right: var(--margin)`}
            />
            <Text title={address_ship_city} />
          </Content>
          <Content region="country">
            <Text
              title="Country:"
              element="h4"
              style={`margin-right: var(--margin)`}
            />
            <Text title={address_ship_country} />
          </Content>
          <Content region="state">
            <Text
              title="State:"
              element="h4"
              style={`margin-right: var(--margin)`}
            />
            <Text title={address_ship_state} />
          </Content>
          <Content region="postalCode">
            <Text
              title="Postal Code:"
              element="h4"
              style={`margin-right: var(--margin)`}
            />
            <Text title={address_ship_postalCode} />
          </Content>
          <AddressTable />
        </Card>
      )}
    </>
  );
}
export default AddressBook;
