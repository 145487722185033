import Card from "../../../components/Card";
import Text from "../../../components/Text";
import Table, { Row, Cell, InputCell } from "../../../components/Table";
import TextButton from "../../../components/TextButton";
import usePage from "../../../usePage/usePage";
import Button from "../../../components/Button";
import ButtonPrimary from "../../../components/ButtonPrimary";
import useServer from "../../../hooks/useServer";
import useGlobalAlert from "../../../context/GlobalAlert/useGlobalAlert";
import { useEffect } from "react";
import psuedo_random_hex from "../../../utils/psuedo_random_hex";
import Content from "../../../components/Content";

export default function CueModal() {
  const {
    state,
    set,
    remove,
    reset,
    reload,
    errorInit,
    errorCleanup,
    errorCheck,
  } = usePage();
  const { post } = useServer();
  const { setAlert } = useGlobalAlert();
  const { cueModal, cueSidebar } = state;

  useEffect(() => {
    cueModal.subProducts.forEach((prod, index) => {
      errorInit({
        [`cueModal.subProducts.${index}.usage`]: {
          minValue: [1, "usage must be greater than 0"],
        },
      });
      return () => {
        errorCleanup({
          [`cueModal.subProducts.${index}.usage`]: {
            minValue: [1, "usage must be greater than 0"],
          },
        });
      };
    });
  }, [errorCleanup, errorInit, cueModal.subProducts]);

  //This need to match the postOrderBatchCreate / formatItems.js model
  const create_subproduct_orderItem = (subProducts) => {
    const date = new Date();
    const orderItems = subProducts.map((prod, index) => {
      return {
        _id: psuedo_random_hex(24),
        orderId: psuedo_random_hex(24),
        orderNumber: "Sub Product",
        orderItemId: "",
        providerItemId: "",
        productId: prod.typeId,
        skuId: prod.sku._id,
        sku: prod.sku.sku,
        name: "",
        product: prod.product.name,
        customerId: prod.customerId,
        createDate: date.toISOString(),
        batchQuantity: prod.usage,
        requirements: [], //this is empty because it is a subproduct but needs to be here for the cueSidebar
      };
    });
    return orderItems;
  };

  const format_sub_products = () => {
    const date = new Date();
    const orderItems = create_subproduct_orderItem(cueModal.subProducts);

    return {
      ...cueSidebar,
      date: date,
      orderItems: [...cueSidebar.orderItems, ...orderItems],
    };
  };

  return (
    <Card
      regions={`
        'title title'
    'table table'
    'text text'
    'no yes'`}
      style={`row-gap: var(--mar);`}
    >
      <Text
        region="title"
        title={`Batch items contain the following sub-products`}
        element="h3"
      />
      <Table region="table" header={`'product sku qty remove'`}>
        <Row style={`background-color: var(--tertiary);`}>
          <Cell region="product" title="Product" />
          <Cell region="sku" title="Sku" />
          <Cell region="qty" title="Quantity" />
          <Cell region="remove" title="" last={true} />
        </Row>
        {cueModal.subProducts.map((prod, i) => {
          return (
            <Row key={prod._id}>
              <Cell region="product" title={prod.product.name} />
              <Cell region="sku" title={prod.sku.sku} />
              <InputCell
                region="qty"
                type="number"
                value={prod.usage}
                error={state.error[`cueModal.subProducts.${i}.usage`]}
                onChange={(e) => {
                  set(
                    `cueModal.subProducts.${i}.usage`,
                    e.target.value === "" ? "" : parseInt(e.target.value)
                  );
                }}
              />
              <Cell last={true}>
                <TextButton
                  title="Remove"
                  onClick={() => {
                    remove("cueModal.subProducts", i);
                  }}
                />
              </Cell>
            </Row>
          );
        })}
      </Table>
      <Text region="text" title="Would you like to add them to the batch?" />
      <Content region="no" style={`gap: var(--mar);`}>
        <Button
          region="no"
          title="Ignore & Create Batch"
          onClick={async () => {
            const payload = {
              ...cueSidebar,
              date: new Date(),
            };
            const data = await post("/order/batchCreate", payload);
            if (data) {
              reload("orders");
              setAlert({ type: "success", msg: "Batch Created" });
              set("mode", "create");
              reset("cueModal");
              reset("cueSidebar");
              reset("cueTable");
            }
          }}
        />
        {cueModal.subProducts.length > 0 && (
          <Button
            region="yes"
            title="Add & Review Batch"
            style={`justify-self: end;`}
            onClick={() => {
              if (errorCheck()) return;
              const payload = format_sub_products();
              set("cueSidebar", payload);
              set("mode", "review");
              reset("cueModal");
            }}
          />
        )}
      </Content>
      {cueModal.subProducts.length > 0 && (
        <ButtonPrimary
          region="yes"
          title="Add & Create Batch"
          onClick={async () => {
            if (errorCheck()) return;
            const payload = format_sub_products();
            const data = await post("/order/batchCreate", payload);
            if (data) {
              reload("orders");
              setAlert({ type: "success", msg: "Batch Created" });
              set("mode", "create");
              reset("cueModal");
              reset("cueSidebar");
              reset("cueTable");
            }
          }}
          style={`justify-self: end;`}
        />
      )}
    </Card>
  );
}
