import TextInput from "../../../../components/TextInput";
import ProcessSteps from "./ProcessSteps";
import useServer from "../../../../hooks/useServer";
import ProcessHeader from "./ProcessHeader";
import useGlobalAlert from "../../../../context/GlobalAlert/useGlobalAlert";
import { useEffect, useRef } from "react";
import ErrorBox from "../../../../components/ErrorBox";
import is_overflown from "../../../../utils/is_overflown";
import usePage from "../../../../usePage/usePage";
import Card from "../../../../components/Card";
import Text from "../../../../components/Text";
import Button from "../../../../components/Button";
import ButtonPrimary from "../../../../components/ButtonPrimary";
import Content from "../../../../components/Content";
import DragList from "../../../../components/DragList";
import DropDown from "../../../../components/DropDown";
import first_char_cap from "../../../../utils/first_char_cap";

const errors = {
  process_name: { minLength: [1, "Process Name is required"] },
  process_steps: { minLength: [1, "At least one step is required"] },
};

function Process() {
  const { state, set, load, func, errorCheck, add, reset, init } = usePage(
    {},
    errors
  );

  const {
    mode,
    selected,
    process_id,
    process_steps,
    process_type,
    process_desc,
    process_name,
    address_customerId,
  } = state;
  const { post } = useServer();
  const { setAlert } = useGlobalAlert();
  const ref = useRef(null);

  const locked = () => {
    if (mode === "edit") return true;
    if (process_type === "sub") return true;
    return false;
  };

  useEffect(() => {
    let direction = "bottom";
    if (process_steps.length <= 1) {
      direction = "top";
    }

    is_overflown(ref.current, direction);
  }, [process_steps.length]);

  const on_save = async () => {
    console.log(errorCheck());
    if (errorCheck()) return;
    const payload = {
      _id: process_id ?? "",
      customerId: address_customerId,
      name: process_name,
      desc: process_desc,
      type: process_type,
      steps: process_steps.map((step) => {
        let constant = step.constant === "" ? 0 : parseFloat(step.constant);
        let cost = step.cost === "" ? 0 : parseFloat(step.cost);
        return { ...step, constant: constant, cost: cost };
      }),
    };
    const data = await post("/customer/upsert/process", payload);
    if (data) {
      init({ process: data.data });
      setAlert({
        type: data.type,
        msg: data.msg,
      });
      reset("process_id");
      reset("process_name");
      reset("process_desc");
      reset("process_steps");
      set("mode", "idle");
      window.scrollTo(0, 0);
      await load(
        "process",
        () => post("/customer/processes", address_customerId),
        {
          type: "init",
          then: (data) => {
            let sub = [];
            let main = [];
            data.data.forEach((process) => {
              if (process.type === "sub") {
                sub.push(process);
              } else {
                main.push(process);
              }
            });
            init({ sub_process: sub });
            (async () => {
              await load(
                "products",
                () => post("/customer/products", address_customerId),
                {
                  type: "init",
                  then: (data) => {
                    return data.data;
                  },
                }
              );
            })(); //Appologies future me for this but we dont' want to change how load works just to suite this one case
            return main;
          },
        }
      );
    }
  };

  const on_cancel = () => {
    set("mode", "idle");
    reset("process_id");
    reset("process_name");
    reset("process_desc");
    reset("process_steps");
  };

  const add_step = () => {
    add("process_steps", {
      desc: "",
      name: "",
      type: "step",
    });
  };

  const button_visibilty = () => {
    if (mode === "new" && process_type === "main") {
      return <Button region="step" title="+ Step" onClick={add_step} />;
    } else {
      return null;
    }
  };

  if (address_customerId === "") return <h4>Load Customer</h4>;
  if (mode !== "idle") {
    return (
      <Card
        ref={ref}
        regions={`
            '. process input1 input1 .' 
            '. desc input2 input2 .'
            '. title type type .'
            '. step step cancelsave .'
            '. steps steps steps .' 
          `}
        style={`row-gap: var(--mar);`}
      >
        <Text region="process" title="Process Name" element="h4" />
        <TextInput
          region="input1"
          value={process_name}
          onChange={(e) => {
            set("process_name", e.target.value);
          }}
          error={state.error["process_name"]}
          placeholder="Process Name"
          required={true}
        />
        <Text region="desc" title="Description" element="h4" />
        <TextInput
          region="input2"
          value={process_desc}
          onChange={(e) => {
            set("process_desc", e.target.value);
          }}
        />
        <Text region="title" title="Process Type" element="h4" />
        <DropDown
          region="type"
          title={first_char_cap(process_type)}
          lock={mode === "edit"}
        >
          <DropDown.Options
            onClick={() => {
              set("process_type", "main");
            }}
          >
            Main
          </DropDown.Options>
          <DropDown.Options
            onClick={() => {
              if (process_steps.length > 1) {
                set("alert_open", true);
                set(
                  "alert_msg",
                  "Switching to a sub-process will reset the steps. Are you sure you want to continue?"
                );
                set("alert_title", "Switch to Sub-Process");
                func("alert_accept", () => {
                  set("process_steps", [{ desc: "", name: "", type: "step" }]);
                  set("process_type", "sub");
                  set("alert_open", false);
                });
              } else {
                set("process_type", "sub");
              }
            }}
          >
            SubProcess
          </DropDown.Options>
        </DropDown>

        {button_visibilty()}
        <Content region="cancelsave" style={`justify-content: end;`}>
          <Button region="cancel" title="Cancel" onClick={on_cancel} />
          <ButtonPrimary region="save" title="Save" onClick={on_save} />
        </Content>
        <ErrorBox error={state.error["process_steps"]} region="steps">
          <Content style={`flex-direction: column;`}>
            <DragList
              list={process_steps}
              callback={(reorder) => {
                set("process_steps", reorder);
              }}
            >
              {process_steps?.map((step, index) => {
                return (
                  <DragList.Item key={`${address_customerId}${index}`}>
                    <ProcessSteps
                      key={index}
                      index={index}
                      step={step}
                      lock={locked()}
                    />
                  </DragList.Item>
                );
              })}
            </DragList>
          </Content>
        </ErrorBox>
      </Card>
    );
  } else {
    return <ProcessHeader />;
  }
}

export default Process;
