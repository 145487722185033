import ErrorBox from "../../../components/ErrorBox";
import useServer from "../../../hooks/useServer";
import TextInput from "../../../components/TextInput";
import { useEffect, useState, useRef } from "react";
import CueSidebarItem from "./CueSidebarItem";
import useGlobalAlert from "../../../context/GlobalAlert/useGlobalAlert";
import is_overflown from "../../../utils/is_overflown";
import usePage from "../../../usePage/usePage";
import DragList from "../../../components/DragList";
import Card from "../../../components/Card";
import Button from "../../../components/Button";
import ButtonPrimary from "../../../components/ButtonPrimary";
import Text from "../../../components/Text";
import Content from "../../../components/Content";

const error = {
  ["cueSidebar.batchName"]: { minLength: [1, "Batch Name is required"] },
  ["cueSidebar.orderItems"]: {
    minLength: [1, "At least one item is required"],
  },
};

function OrderCueSidebar() {
  const { state, set, reset, errorCheck, errorForce, reload } = usePage(
    {},
    error
  );
  const { cueSidebar, mode, products } = state;
  const { post } = useServer();
  const [errorMsg, setErrorMsg] = useState("Batch Name is required");
  const [placeHolder, setPlaceHolder] = useState("Batch Name");
  const { setAlert } = useGlobalAlert();
  const ref = useRef(null);

  useEffect(() => {
    let direction = "bottom";
    if (cueSidebar.orderItems.length <= 1) {
      direction = "top";
    }
    is_overflown(ref.current, direction, ref.current);
  }, [cueSidebar.orderItems.length]);

  const on_clear = () => {
    reset("orders");
    reset("cueTable_loading");
    reset("cueTable");
    reset("cueSidebar");
    set("cueSidebar.open", true);
  };

  const on_create = async () => {
    if (errorCheck()) {
      return;
    }

    const serverValidated = await post("/order/batchValidate", cueSidebar);
    if (serverValidated?.isError) {
      setPlaceHolder(cueSidebar.batchName);
      set("cueSidebar.batchName", "");
      errorForce("cueSidebar.batchName");
      setErrorMsg(serverValidated.errorMsg);
      return;
    }

    const subProducts = {};
    cueSidebar.orderItems.forEach((item) => {
      item.requirements.forEach((req) => {
        if (!subProducts[req.typeId]) {
          subProducts[req.typeId] = {
            ...req,
            customerId: item.customerId,
            product: req.product,
            sku: req.sku,
          };
        } else {
          subProducts[req.typeId].usage += req.usage;
        }
      });
    });

    const subProductCount = Object.keys(subProducts).length;

    if (mode === "queue" && subProductCount > 0) {
      set("cueModal.open", true);
      set("cueModal.subProducts", Object.values(subProducts));
    } else if (mode === "queue" || mode === "review") {
      const date = new Date();
      const payload = {
        ...cueSidebar,
        date: date,
      };
      const data = await post("/order/batchCreate", payload);
      if (data) {
        reload("orders");
        setAlert({ type: "success", msg: "Batch Created" });
        set("mode", "create");
        reset("cueSidebar");
        reset("cueTable");
      }
    }
  };

  //COMPONENT
  return (
    <Card
      region="sidebar"
      regions={`'menu' 'list'`}
      style={`box-shadow: var(--shadow); height: 79vh;`}
    >
      <Card
        region="menu"
        regions={`'clear create' 'name name'`}
        style={`margin: 0; padding: 0; row-gap: var(--mar);`}
      >
        <Button
          region="clear"
          title="Clear"
          onClick={on_clear}
          style={`justify-self: start`}
        />
        <ButtonPrimary
          region="create"
          title="Create"
          onClick={on_create}
          style={`justify-self: end;`}
        />
        <Content region="name" style={`justify-content: center;`}>
          <Text title="Batch Name:" element="b" />
          <TextInput
            placeholder={placeHolder}
            value={cueSidebar?.batchName}
            onChange={(e) => {
              set("cueSidebar.batchName", e.target.value);

              setErrorMsg("Batch Name is required");
            }}
            error={state.error["cueSidebar.batchName"] ? errorMsg : false}
          />
        </Content>
      </Card>

      <ErrorBox error={state.error["cueSidebar.orderItems"]}>
        <Content
          ref={ref}
          id="cue-sidebar"
          style={`flex-direction: column; height: 65vh; border: solid 2px var(--text); border-radius: var(--border-radius); overflow-y: auto;`}
        >
          <DragList
            list={cueSidebar.orderItems}
            callback={(reorder) => {
              set("cueSidebar.orderItems", reorder);
            }}
          >
            {cueSidebar?.orderItems?.map((item, index) => {
              return (
                <DragList.Item key={`${item._id}`}>
                  <CueSidebarItem
                    item={item}
                    index={index}
                    setPlaceHolder={setPlaceHolder}
                    setErrorMsg={setErrorMsg}
                  />
                </DragList.Item>
              );
            })}
          </DragList>
        </Content>
      </ErrorBox>
    </Card>
  );
}
export default OrderCueSidebar;
