import ProductHeader from "./ProductHeader";
import is_overflown from "../../../../utils/is_overflown";
import TextInput from "../../../../components/TextInput";

import useServer from "../../../../hooks/useServer";
import format_product_payload from "../../format_product_payload";
import DropDown from "../../../../components/DropDown";
import ProductParts from "./ProductParts";
import useGlobalAlert from "../../../../context/GlobalAlert/useGlobalAlert";
import { useRef, useEffect } from "react";
import usePage from "../../../../usePage/usePage";
import Card from "../../../../components/Card";
import Text from "../../../../components/Text";
import Content from "../../../../components/Content";
import Button from "../../../../components/Button";
import ButtonPrimary from "../../../../components/ButtonPrimary";
import SkuTable from "./SkuTable";
import ModalBase from "../../../../components/ModalBase";
import ProductModal from "./ProductModal";
import CheckBox from "../../../../components/CheckBox";

const errors = {
  product_name: {
    minLength: [1, "Product Name is required"],
    maxLength: [150, "Product Name must be less than 150 characters"],
  },
  product_desc: {
    maxLength: [250, "Description must be less than 250 characters"],
  },
  product_process_id: {
    minLength: [1, "Process is required"],
  },
};

function Product() {
  const { state, set, func, reset, reload, add, errorCheck, errorInit } =
    usePage({}, errors);
  const {
    address_customerId,
    product_lock,
    product_id,
    product_name,
    product_desc,
    product_skus,
    product_type,
    product_components,
    product_process_id,
    product_process_name,
    product_modal_open,
    mode,
    process,
  } = state;

  const { post } = useServer();
  const { setAlert } = useGlobalAlert();

  const resetProduct = () => {
    reset("product_modal_open");
    reset("product_modal_from");
    reset("product_modal_to");
    reset("product_search");
    reset("product_skus_delete");
    reset("product_skus");
    reset("product_lock");
    reset("product_id");
    reset("product_name");
    reset("product_desc");
    reset("product_type");
    reset("product_process_id");
    reset("product_process_name");
    reset("product_sub_process");
    reset("product_parts");
  };

  const on_submit = async () => {
    if (errorCheck()) return;
    const payload = format_product_payload(state);
    const data = await post("/customer/upsert/product", payload);
    if (data.type === "error") {
      setAlert({ type: data.type, msg: data.msg });
      window.scrollTo(0, 0);
      return;
    }
    if (data) {
      setAlert({ type: data.type, msg: data.msg });
      set("mode", "idle");
      resetProduct();
      reload("products");
      window.scrollTo(0, 0);
    }
  };

  const accept_archive = async () => {
    const data = await post("/customer/archive/product", {
      _id: product_id,
    });
    if (data) {
      setAlert({ type: data.type, msg: data.msg });
      set("alert.open", false);
      set("mode", "idle");
      resetProduct();
      reload("products");
    }
  };

  if (address_customerId === "") return <h4>Load Customer</h4>;
  if (mode !== "idle") {
    return (
      <>
        <ModalBase
          title="Copy Requirements"
          open={product_modal_open}
          close={() => {
            set("product_modal_open", false);
          }}
        >
          <ProductModal />
        </ModalBase>

        <Card
          regions={`
      'name input1 input1'
      'desc input2 input2'
      'process input3 input3'
      'type checkbox checkbox'
      'buttons buttons buttons'
      'sub sub sub'
      'parts parts parts'
      'skus skus skus'
      `}
          style={`width: 75vw;`}
        >
          <Text region="name" title="Product Name:" element="h4" />
          <TextInput
            region="input1"
            value={product_name}
            onChange={(e) => {
              set("product_name", e.target.value);
            }}
            error={state.error["product_name"]}
            placeholder="Product Name"
            required={true}
            lock={product_lock}
          />
          <Text region="desc" title="Description:" element="h4" />
          <TextInput
            region="input2"
            value={product_desc}
            onChange={(e) => {
              set("product_desc", e.target.value);
            }}
            placeholder="Brief Description"
            required={false}
            lock={false}
          />
          <Text region="process" title="Process:" element="h4" />
          <DropDown
            region="input3"
            title={
              !product_process_name ? "Choose Process" : product_process_name
            }
            value={product_process_id}
            required={true}
            error={state.error["product_process_id"]}
            lock={mode === "edit"}
          >
            {process.map((each) => {
              return (
                <DropDown.Options
                  className="dropdown-option"
                  onClick={() => {
                    set("product_process_id", each._id);
                    set("product_process_name", each.name);
                  }}
                  key={each._id}
                  value={each._id}
                >
                  {each.name}
                </DropDown.Options>
              );
            })}
          </DropDown>
          <Text
            region="type"
            title="Type:"
            element="h4"
            style={`margin-top: var(--mar);`}
          />
          <Content
            region="checkbox"
            style={`align-items:center; margin-top: var(--mar); gap: var(--margin);`}
          >
            <Text title="Product" />
            <CheckBox
              value={product_type === "product"}
              style={`margin-right: 50px`}
              lock={mode === "edit"}
              onClick={() => {
                set("product_type", "product");
                set("product_skus", [
                  {
                    edit: true,
                    show: true,
                    sku: "",
                    customerId: address_customerId,
                    requirements: [],
                  },
                ]);
              }}
            />
            <Text title="Sub-Product" />
            <CheckBox
              value={product_type === "sub"}
              lock={mode === "edit"}
              onClick={() => {
                if (product_skus.length > 1) {
                  set("alert_open", true);
                  set(
                    "alert_msg",
                    "Switching to a sub-product will reset the skus. Are you sure you want to continue?"
                  );
                  set("alert_title", "Switch to Sub-Product");
                  func("alert_accept", () => {
                    set("product_skus", [
                      {
                        edit: true,
                        show: true,
                        sku: "",
                        customerId: address_customerId,
                        requirements: [],
                      },
                    ]);
                    set("product_type", "sub");
                    set("alert_open", false);
                  });
                } else {
                  set("product_type", "sub");
                  set("product_skus", [
                    {
                      edit: true,
                      show: true,
                      sku: "",
                      customerId: address_customerId,
                      requirements: [],
                    },
                  ]);
                }
              }}
            />
          </Content>

          <Card
            regions={`'cancel submit'`}
            region="buttons"
            style={`margin: 0; padding: 0;`}
          >
            <Button
              title="Cancel"
              onClick={() => {
                set("mode", "idle");
                reset("product_lock");
                reset("product_id");
                reset("product_name");
                reset("product_desc");
                reset("product_delimiter");
                reset("product_schema");
                reset("product_sample");
                reset("product_process_id");
                reset("product_process_name");
                reset("product_parts");
                reset("product_components");
                reset("product_skus");
                reset("product_skus_delete");
                reset("product_type");
              }}
            />
            <Content region="submit" style={`justify-content: flex-end;`}>
              <ButtonPrimary
                title="Save"
                onClick={on_submit}
                style={`margin-right: var(--margin);`}
              />
              {mode === "edit" && (
                <Button
                  title="Archive"
                  onClick={() => {
                    set("alert_open", true);
                    set("alert_title", "Archive Product");
                    set(
                      "alert_msg",
                      `Are you sure you want to archive "${product_name}"? This cannot be undone`
                    );
                    func("alert_accept", accept_archive);
                  }}
                />
              )}
            </Content>
          </Card>
          {/* <SubProcess /> */}
          <ProductParts />
          <SkuTable />
        </Card>
      </>
    );
  } else {
    return <ProductHeader />;
  }
}
export default Product;
