export default async function order_async_format_modal(order, set, post) {
  const OrderDetailsLines = async (order, post, products) => {
    const lines = await Promise.all(
      order.orderItems.map(async (item, index) => {
        console.log(item);
        return {
          _id: item._id,
          providerItemId: item.providerItemId,
          // batchName: batchFromItem(order, item),
          skuId: item.skuId,
          sku: item.sku,
          skus: item.productId
            ? await post("/customer/skus", item.productId)
            : [],
          name: item.name,
          product: item.product,
          quantity: item.quantity,
          batched: item.batched,
          allocate: item.allocate,
          unitPrice: item.unitPrice,
          productId: item.productId,
        };
      })
    );

    return {
      lock: true,
      lines,
    };
  };

  set("modal.orderItems_loading", true);
  const products = await post("/customer/products", order.customerId);
  let orderItems = null;
  if (products) {
    orderItems = await OrderDetailsLines(order, post, products);
  }
  console.log(products);

  if (orderItems && products) {
    set("modal.orderItems", orderItems);
    set("products", products.data);
    set("modal.orderItems_loading", false);
  }
}
