import usePage from "../../../usePage/usePage";
import Card from "../../../components/Card";
import Button from "../../../components/Button";
import Text from "../../../components/Text";
import Content from "../../../components/Content";
import Table, { Cell, Row, StickyRow } from "../../../components/Table";
import { useEffect } from "react";
import Spinner from "../../../icons/Spinner";

function OrderCueTable() {
  const { state, set, add, reset } = usePage();
  const { orders, cueTable, cueSidebar, customers } = state;

  function on_add(cueOrder, orderItem) {
    const addItem = {};
    if (cueSidebar.orderItems.find((item) => item._id === orderItem._id))
      return;
    addItem._id = orderItem._id;
    addItem.orderId = cueOrder._id;
    addItem.orderNumber = cueOrder.orderNumber;
    addItem.customerId = cueOrder.customerId;
    addItem.edit = false;
    addItem.createDate = new Date().toISOString();
    addItem.batchQuantity = orderItem.quantity;
    addItem.skuId = orderItem.skuId;
    addItem.sku = orderItem.sku;
    addItem.skuVersion = orderItem.skuVersion;
    addItem.name = orderItem.name;
    addItem.productId = orderItem.productId;
    addItem.product = orderItem.product;
    addItem.productVersion = orderItem.productVersion;
    addItem.requirements = orderItem.requirements;

    const update = [...orders].map((order) => {
      if (order._id === cueTable._id) {
        return {
          ...order,
          orderItems: [
            ...order.orderItems.map((item) => {
              if (item._id === orderItem._id) {
                return {
                  ...item,
                  batch: [
                    ...item.batch,
                    {
                      batchName: "Batching...",
                      _id: "temp",
                      quantity: item.quantity,
                    },
                  ],
                };
              }
              return item;
            }),
          ],
        };
      }
      return order;
    });
    set("orders", update);
    add("cueSidebar.orderItems", addItem);
  }

  function on_add_all() {
    const orderItems = cueTable.orderItems;
    const addItems = orderItems.filter((item) => {
      return !cueSidebar.orderItems.find((add) => add._id === item._id);
    });
    const payload = [];
    let itemIds = [];

    addItems.forEach((item) => {
      const newItem = {
        ...item,
        orderId: cueTable._id,
        orderNumber: cueTable.orderNumber,
        customerId: cueTable.customerId,
        edit: false,
        createDate: new Date().toISOString(),
        batchQuantity: item.quantity,
      };
      itemIds.push(item._id);
      payload.push(newItem);
    });

    const update = [...orders].map((order) => {
      if (order._id === cueTable._id) {
        return {
          ...order,
          orderItems: [
            ...order.orderItems.map((i) => {
              if (itemIds.includes(i._id)) {
                return {
                  ...i,
                  batch: [
                    ...i.batch,
                    {
                      batchName: "Batching...",
                      _id: "temp",
                      quantity: i.quantity,
                    },
                  ],
                };
              }
              return i;
            }),
          ],
        };
      }
      return order;
    });

    set("orders", update);
    payload.forEach((item) => {
      add("cueSidebar.orderItems", item);
    });
  }

  const customer_lookup = (each) => {
    const customer = customers.find(
      (customer) => customer._id === each.customerId
    );
    return customer;
  };

  useEffect(() => {
    //fix this
    const order = orders.find((order) => order._id === cueTable._id);
    const customer = customer_lookup(order);
    const payload = {
      ...order,
      open: true,
      customerName: customer.profileName,
    };
    set("cueTable", payload);
  }, [orders]);

  const setQtyColor = (item) => {
    if (set_item_totals(item) === item.quantity)
      return { color: "var(--primary)" };
    if (set_item_totals(item) > item.quantity)
      return { color: "var(--warning)" };
    return {};
  };

  const get_batch_totals = (batch) => {
    let totals = 0;
    batch.forEach((b) => {
      totals += b.quantity;
    });
    return totals;
  };

  const set_item_totals = (item) => {
    // const allocate = item.allocate;
    const batched = get_batch_totals(item.batch);
    return batched;
  };

  //COMPONENT
  return (
    <Card
      region="table"
      regions={`'add close' 'order customer' 'table table'`}
      style={`box-shadow: var(--shadow);`}
    >
      <Button
        region="close"
        title="Close"
        onClick={() => {
          reset("cueTable_loading");
          reset("cueTable");
        }}
        style={`justify-self: end;`}
      />

      <Button region="add" title="Add All" onClick={on_add_all} />
      <Content region="order" style={`gap: var(--mar);`}>
        <Text title="Order -" element="h5" />
        <Text title={cueTable.orderNumber} element="h3" />
      </Content>
      <Content region="customer" style={`gap: var(--mar);`}>
        <Text title="Customer -" element="h5" />
        <Text title={cueTable.customerName} element="h3" />
      </Content>

      <Table
        region="table"
        header={`'outof name name sku sku product product'`}
      >
        <StickyRow style={`background-color: var(--tertiary);`}>
          <Cell region="outof" title="Qty" element="h4" />
          <Cell region="name" title="Name" element="h4" />
          <Cell region="sku" title="Sku" element="h4" />
          <Cell region="product" title="Product" element="h4" />
        </StickyRow>
        {cueTable.orderItems?.map((item) => {
          return (
            <Row
              key={item._id}
              style={!item.product ? `border: solid var(--warning) 1px` : null}
            >
              <Cell region="outof">
                <h6
                  className="list"
                  style={setQtyColor(item)}
                >{`${set_item_totals(item)}/`}</h6>
                <h3 className="list" style={setQtyColor(item)}>
                  {item.quantity}
                </h3>
              </Cell>
              <Cell
                region="name"
                title={item.name}
                hover={true}
                onClick={() => {
                  on_add(cueTable, item);
                }}
              />
              <Cell
                region="sku"
                title={item.sku}
                hover={true}
                onClick={() => {
                  on_add(cueTable, item);
                }}
              />
              <Cell
                region="product"
                title={!item.product ? "N/A" : item.product}
                textStyle={!item.product ? `color: var(--warning)` : null}
              />
            </Row>
          );
        })}
      </Table>
    </Card>
  );
}
export default OrderCueTable;
