import useIntercept from "../context/User/useIntercept";
import { useCallback } from "react";

export default function useServer() {
  const axiosProtected = useIntercept();

  const get = useCallback(
    async (route, query = null) => {
      const response = await axiosProtected.get(route, { params: query });
      return response.data;
    },
    [axiosProtected]
  );

  const post = useCallback(
    async (route, data) => {
      const response = await axiosProtected.post(route, { data: data });
      return response.data;
    },
    [axiosProtected]
  );

  return { get, post };
}
