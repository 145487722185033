import styled from "styled-components";
import React, {
  useContext,
  useState,
  createContext,
  useEffect,
  useRef,
  useCallback,
} from "react";

//TODO:: refactor idea. give the individual cards more positional information and a lock for their transform property.
//then when dropped have the animation calculate the transition between the two card origins and animate the transition

//MAYBE:: add a feature that doesn't need a list specified and instead just maps all the children to the list

const DragContext = createContext();

const DragListStyle = styled.div`
  ${(props) => props.$more}
`;

const DragList = ({ children, list, callback, style }) => {
  const [drag, setDrag] = useState(null);
  const [array, setArray] = useState([]);
  const [handles, setHandles] = useState([]);
  const childRefs = useRef([]);

  const reorder_array = useCallback((drag, drop, array) => {
    let arrayCopy = [...array];
    const list = arrayCopy.splice(drag, 1);
    const frontList = arrayCopy.slice(0, drop);
    const rearList = arrayCopy.slice(drop);
    const updatedArray = [...frontList, ...list, ...rearList];
    return updatedArray;
  }, []);

  const check_collision = useCallback(() => {
    if (drag === null) return false;
    let box = array[drag];
    for (let i = 0; i < array.length; i++) {
      if (i !== drag) {
        let other = array[i];
        if (
          box.left < other.right &&
          box.right > other.left &&
          box.top < other.bottom &&
          box.bottom > other.top
        ) {
          return i;
        }
      }
    }
    return false;
  }, [array, drag]);

  const addHandle = useCallback((handle) => {
    setHandles((prev) => [...prev, handle]);
  }, []);

  const handleMouseDown = useCallback(
    (e, index, dragging) => {
      const isHandle = handles.some((handle) => handle.contains(e.target));
      if (!isHandle) return;

      dragging(true);
      setDrag(index);
    },
    [handles]
  );

  const handleMouseMove = useCallback(
    (e) => {
      if (drag !== null) {
        // let i = check_collision();
        // if (i !== false) {
        //   setArray((prevArray) => {
        //     let newArray = [...prevArray];
        //     return newArray.map((item, j) => {
        //       if (j === i) {
        //         item.over = true;
        //       } else if (prevArray[j].over) {
        //         item.leaving = true;
        //         item.over = false;
        //       } else {
        //         item.over = false;
        //         item.leaving = false;
        //       }
        //       return item;
        //     });
        //   });
        // } else {
        //   setArray((prevArray) => {
        //     let newArray = [...prevArray];
        //     return newArray.map((item, j) => {
        //       if (prevArray[j].over) {
        //         item.leaving = true;
        //         item.over = false;
        //       } else {
        //         item.over = false;
        //         item.leaving = false;
        //         return item;
        //       }
        //     });
        //   });
        // }

        setArray((prevArray) => {
          let newArray = [...prevArray];
          newArray[drag] = {
            ...newArray[drag],
            left: newArray[drag].left + e.movementX,
            right: newArray[drag].right + e.movementX,
            top: newArray[drag].top + e.movementY,
            bottom: newArray[drag].bottom + e.movementY,
          };
          return newArray;
        });
      }
    },
    [drag]
  );
  const handleMouseUp = useCallback(() => {
    let i = check_collision();
    if (i !== false) {
      let update = reorder_array(drag, i, list);
      callback(update);
      setArray((prevArray) => {
        return prevArray.map((item) => {
          item.over = false;
          item.leaving = false;
          return item;
        });
      });
    }
    setDrag(null);
  }, [drag, array, check_collision, reorder_array, callback, list]);

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]);

  useEffect(() => {
    childRefs.current.forEach((ref, index) => {
      if (ref) {
        const bounds = ref.getBoundingClientRect();
        let box = {
          left: bounds.left,
          right: bounds.right,
          top: bounds.top,
          bottom: bounds.bottom,
          over: false,
          leaving: false,
        };
        setArray((prevArray) => {
          let newArray = [...prevArray];
          newArray[index] = box;
          return newArray;
        });
      }
    });
  }, [children]);

  return (
    <DragContext.Provider
      value={{
        list,
        array,
        drag,
        handles,
        setDrag,
        setArray,
        addHandle,
        callback,
        handleMouseDown,
      }}
    >
      <DragListStyle $more={style}>
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child, {
            ref: (el) => (childRefs.current[index] = el),
            index,
          });
        })}
      </DragListStyle>
    </DragContext.Provider>
  );
};

const DragItemStyle = styled.div`
  ${(props) =>
    props.$dragging
      ? `position: relative; z-index:99; user-select: none; opacity: 0.65; `
      : ""}
  ${(props) => (props.$locked ? ` user-select: none;` : "")}
  ${(props) => (props.$over ? `animation: shift 0.5s ease forwards;` : "")}
      @keyframes shift {
    100% {
      transform: translate(5px, 5px);
    }
  }
  ${(props) =>
    props.$leaving
      ? `animation: unshift 0.5s ease forwards;
      @keyframes unshift {
      0% {
      transform: translate(5px, 5px);
      } 
      100% {
      transform: translate(0, 0);
    }
}`
      : ""}
`;

export const Item = React.forwardRef(({ children, index }, ref) => {
  const { array, drag, handleMouseDown } = useContext(DragContext);

  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);

  const handleMouseMove = useCallback(
    (e) => {
      if (!dragging) return;
      setPosition((prev) => ({
        x: prev.x + e.movementX,
        y: prev.y + e.movementY,
      }));
    },
    [dragging]
  );

  const handleMouseUp = useCallback(() => {
    setDragging(false);
    setPosition({ x: 0, y: 0 });
  }, []);

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]);

  return (
    <DragItemStyle
      ref={ref}
      $dragging={drag !== null && drag === index}
      $locked={drag !== null && drag !== index}
      $over={array?.[index]?.over ?? false}
      $leaving={array?.[index]?.leaving ?? false}
      onMouseDown={(e) => {
        handleMouseDown(e, index, setDragging);
        // setDragging(true);
      }}
      style={{
        transform: `translate(${position.x}px, ${position.y}px)`,
      }}
    >
      {children}
    </DragItemStyle>
  );
});

const HandleStyle = styled.div`
  cursor: grab;
  ${(props) => props.$more}
`;

export const Handle = ({ children, style }) => {
  const { addHandle } = useContext(DragContext);
  const self = useRef(null);

  useEffect(() => {
    addHandle(self.current);
  }, [addHandle]);

  return (
    <HandleStyle $more={style} ref={self}>
      {children}
    </HandleStyle>
  );
};

DragList.Item = Item;
DragList.Handle = Handle;
export default DragList;
