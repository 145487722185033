import Lock from "../../../icons/Lock";
import Unlock from "../../../icons/Unlock";
import ErrorBox from "../../../components/ErrorBox";
import usePage from "../../../usePage/usePage";
import useServer from "../../../hooks/useServer";
import React, { useEffect } from "react";
import Card from "../../../components/Card";
import ButtonIcon from "../../../components/ButtonIcon";
import Button from "../../../components/Button";
import CloseIcon from "../../../icons/Close";
import Text from "../../../components/Text";
import Table, {
  Row,
  StickyRow,
  Cell,
  DynamicCell,
  InputCell,
} from "../../../components/Table";
import DropDownBorderless from "../../../components/DropDownBorderless";

const errors = {
  ["modal.orderItems.lines"]: {
    minLength: [1, "At least one item is required"],
  },
};

function OrderItems(props) {
  const { title } = props;
  const { post } = useServer();
  const {
    state,
    set,
    add,
    remove,
    toggle,
    errorInit,
    errorCleanup,
    errorForce,
  } = usePage({}, errors);
  const { products, modal } = state;
  const lock = state.modal.orderItems.lock;

  useEffect(() => {
    modal?.orderItems?.lines?.forEach((item, index) => {
      errorInit({
        [`modal.orderItems.lines.${index}.quantity`]: {
          minValue: [1, "Quantity must be greater than 0"],
        },
        [`modal.orderItems.lines.${index}.sku`]: {
          minLength: [1, "Sku is required"],
        },
        [`modal.orderItems.lines.${index}.product`]: {
          minLength: [1, "Product is required"],
        },
      });
    });
    return () => {
      modal?.orderItems?.lines?.forEach((item, index) => {
        errorCleanup({
          [`modal.orderItems.lines.${index}.quantity`]: {
            minValue: [1, "Quantity must be greater than 0"],
          },
          [`modal.orderItems.lines.${index}.sku`]: {
            minLength: [1, "Sku is required"],
          },
          [`modal.orderItems.lines.${index}.product`]: {
            minLength: [1, "Product is required"],
          },
        });
      });
    };
  }, [modal.orderItems.lines]);

  const addLine = () => {
    add(`modal.orderItems.lines`, {
      providerItemId: "",
      productId: "",
      product: "",
      name: "",
      sku: "",
      subProducts: [],
      quantity: "",
      unitPrice: "",
    });
  };

  const setLine = async (product, index) => {
    set(`modal.orderItems.lines.${index}.product`, product.name);
    set(`modal.orderItems.lines.${index}.productId`, product._id);
    const data = await post("/customer/skus", product._id);

    if (data) {
      set(`modal.orderItems.lines.${index}.skus`, data);
      set(`modal.orderItems.lines.${index}.sku`, "");
    }
  };

  return (
    <Card regions={`'lock' 'lines' 'button'`}>
      <ButtonIcon
        region="lock"
        onClick={() => toggle("modal.orderItems.lock")}
        style={`  justify-self: end; margin-bottom: var(--mar);`}
      >
        {!lock ? <Unlock /> : <Lock />}
      </ButtonIcon>
      <ErrorBox error={state.error["modal.orderItems.lines"]}>
        <Table
          region="lines"
          header={`'name name name prod prod sku sku qty cost close'`}
        >
          <StickyRow style={`background-color: var(--tertiary);`}>
            <Cell region="name" title="Name" element="h3" />
            <Cell region="prod" title="Product" element="h3" />
            <Cell region="sku" title="Sku" element="h3" />
            <Cell region="qty" title="Quantity" element="h3" />
            <Cell region="cost" title="Price $/unit" element="h3" />
            <Cell region="close" element="h3" />
          </StickyRow>
          {modal?.orderItems?.lines?.map((line, index) => {
            return (
              <Row key={`line-${index}`}>
                <InputCell
                  region="name"
                  type="text"
                  lock={lock}
                  value={line.name}
                  onChange={(e) => {
                    set(`modal.orderItems.lines.${index}.name`, e.target.value);
                  }}
                />

                <DynamicCell region="prod" style={`padding: 0;`}>
                  <DropDownBorderless
                    title={line.product !== "" ? line.product : "Products"}
                    css="third"
                    lock={lock}
                    value={line.productId}
                    error={
                      state.error[`modal.orderItems.lines.${index}.product`]
                    }
                  >
                    {products?.map((product, i) => {
                      if (product.type !== "product") return <></>;
                      return (
                        <DropDownBorderless.Options
                          key={`${product._id}-${i}`}
                          value={product.id}
                          onClick={() => {
                            setLine(product, index);
                          }}
                        >
                          {product.name}
                        </DropDownBorderless.Options>
                      );
                    })}
                  </DropDownBorderless>
                </DynamicCell>
                <DynamicCell region="sku" style={`padding: 0;`}>
                  <DropDownBorderless
                    title={line.sku !== "" ? line.sku : "Choose Sku"}
                    lock={lock}
                    value={line.sku}
                    error={state.error[`modal.orderItems.lines.${index}.sku`]}
                  >
                    {line?.skus?.map((each) => {
                      return (
                        <DropDownBorderless.Options
                          className="DropDownBorderless-option"
                          key={each._id}
                          onClick={() => {
                            if (
                              modal?.orderItems?.lines?.some(
                                (item) => item.skuId === each._id
                              )
                            ) {
                              errorForce(
                                `modal.orderItems.lines.${index}.sku`,
                                `Duplicate Sku ${each.sku}`
                              );
                              return;
                            }
                            set(
                              `modal.orderItems.lines.${index}.sku`,
                              each.sku
                            );
                            set(
                              `modal.orderItems.lines.${index}.skuId`,
                              each._id
                            );
                            set(
                              `modal.orderItems.lines.${index}.subProducts`,
                              each.subProducts
                            );
                          }}
                        >
                          {each.sku}
                        </DropDownBorderless.Options>
                      );
                    })}
                  </DropDownBorderless>
                </DynamicCell>
                <InputCell
                  type="number"
                  region="qty"
                  value={line.quantity}
                  lock={lock}
                  onChange={(e) => {
                    set(
                      `modal.orderItems.lines.${index}.quantity`,
                      e.target.value
                    );
                  }}
                  error={
                    state.error[`modal.orderItems.lines.${index}.quantity`]
                  }
                />
                <InputCell
                  region="cost"
                  type="number"
                  value={line.unitPrice}
                  lock={lock}
                  onChange={(e) => {
                    set(
                      `modal.orderItems.lines.${index}.unitPrice`,
                      e.target.value
                    );
                  }}
                />

                <Cell style={`justify-content: center; align-content: center;`}>
                  {!lock && (
                    <ButtonIcon
                      onClick={() => {
                        remove(`modal.orderItems.lines`, index);
                      }}
                    >
                      <CloseIcon />
                    </ButtonIcon>
                  )}
                </Cell>
              </Row>
            );
          })}
        </Table>
      </ErrorBox>
      <Button lock={lock} region="button" title="+ Item" onClick={addLine} />
    </Card>
  );
}
export default OrderItems;
