import styled from "styled-components";
import Close from "../icons/Close";
import Ellipse from "../icons/Ellipse";
import Text from "./Text";
import ButtonIcon from "./ButtonIcon";
import { useEffect, useRef } from "react";
import is_overflown from "../utils/is_overflown";

const Background = styled.div`
  position: fixed;
  display: block;
  z-index: 3;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  color: #000000;
`;

const Container = styled.div`
  background-color: #fefefe;
  margin: 5% auto;
  border-radius: 5px;
  width: 80%;
  box-shadow: var(--shadow);
`;

const Header = styled.div`
  display: grid;
  grid-template-areas: ${(props) => props.$regions};
  padding: 1rem;
  border-radius: 5px 5px 0 0;
  background-color: var(--tertiary);
`;

export default function ModalBase({ children, ...props }) {
  const ref = useRef(null);
  const {
    open,
    title,
    msg,
    close,
    loading,
    alert = { type: "none" },
    checkOverflow = false,
    overflowDirection = "bottom",
    overflowReason = null,
  } = props;

  useEffect(() => {
    if (checkOverflow) {
      is_overflown(ref.current, overflowDirection, ref.current);
    }
  }, [checkOverflow, overflowDirection, overflowReason]);

  const alertType = () => {
    if (alert.type === "error") {
      return "var(--error)";
    } else if (alert.type === "success") {
      return "var(--primary)";
    } else if (alert.type === "warning") {
      return "var(--warning)";
    } else {
      return "var(--text)";
    }
  };

  return (
    open && (
      <Background ref={ref}>
        <Container>
          <Header $regions={`'h1 h3 h3 h3 h3 h3 button'`}>
            <Text region="h1" element="h1" title={title} />
            <Text
              region="h3"
              element="h3"
              title={loading === true ? <Ellipse /> : alert.msg}
              style={{ color: alertType(), justifySelf: "start" }}
            />
            <ButtonIcon
              region="button"
              onClick={close}
              style={{ justifySelf: "end" }}
            >
              <Close />
            </ButtonIcon>
          </Header>
          {children}
        </Container>
        <div style={{ marginBottom: "200px" }}></div>
      </Background>
    )
  );
}
