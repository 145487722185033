import TextInput from "../../components/TextInput";
import NumberInput from "../../components/NumberInput";
import DropDown from "../../components/DropDown";
import useGlobalAlert from "../../context/GlobalAlert/useGlobalAlert";
import useServer from "../../hooks/useServer";
import ErrorBox from "../../components/ErrorBox";
import usePage from "../../usePage/usePage";
import Card from "../../components/Card";
import Text from "../../components/Text";
import PasswordInput from "../../components/PasswordInput";
import ButtonPrimary from "../../components/ButtonPrimary";

const errors = {
  m1_firstName: { minLength: [1, "First Name is required"] },
  m1_lastName: { minLength: [1, "Last Name is required"] },
  m1_email: { minLength: [1, "Email is required"] },
  m1_pay: { minLength: [1, "Pay must be greater than 0"] },
  m1_role: { minLength: [1, "Please select a role"] },
};

export default function NewEmployee() {
  const { state, set, errorCheck, errorForce, reset, reload } = usePage(
    {},
    errors
  );
  const { post } = useServer();
  const { setAlert } = useGlobalAlert();

  const {
    m1_id,
    m1_confirmPassword,
    m1_email,
    m1_firstName,
    m1_lastName,
    m1_password,
    m1_pay,
    m1_phone,
    m1_render,
    m1_role,
  } = state;

  //FUNCTIONS
  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (errorCheck()) return;

    const payload = {
      _id: m1_id,
      firstName: m1_firstName,
      lastName: m1_lastName,
      email: m1_email,
      password: m1_password,
      confirmPassword: m1_confirmPassword,
      phone: m1_phone,
      pay: m1_pay,
      role: m1_role,
    };
    const data = await post("/employee/new", payload);
    if (data.type === "error") {
      errorForce(data.key, data.msg);
    } else {
      setAlert({ msg: data.msg, type: data.type });
      reload("employees");
      reset();
    }
  };

  //COMPONENT
  return (
    <ErrorBox error={state.error["m1_alert"]}>
      <Card
        regions={`
    '. label1 input1 input1 .'
    '. label2 input2 input2 .'
    '. label3 input3 input3 .'
    '. label4 input4 input4 .'
    '. label5 input5 input5 .'
    '. label6 input6 input6 .'
    '. label7 input7 input7 .'
    '. label8 input8 input8 .'
    '. . . button .'
    `}
      >
        <Text region="label1" element="h4" title="First Name" />
        <TextInput
          region="input1"
          value={m1_firstName}
          onChange={(e) => {
            set("m1_firstName", e.target.value);
          }}
          required={true}
          error={state.error["m1_firstName"]}
          placeholder="First Name"
        />

        <Text title="Last Name" element="h4" region="label2" />
        <TextInput
          region="input2"
          value={m1_lastName}
          onChange={(e) => {
            set("m1_lastName", e.target.value);
          }}
          required={true}
          error={state.error["m1_lastName"]}
          placeholder="Last Name"
        />

        <Text title="Email" element="h4" region="label3" />
        <TextInput
          region="input3"
          value={m1_email}
          onChange={(e) => {
            set("m1_email", e.target.value);
          }}
          render={m1_render}
          required={true}
          error={state.error["m1_email"]}
          placeholder="example@email.com"
        />

        <Text title="Password" element="h4" region="label4" />
        <PasswordInput
          region="input4"
          error={state.error["m1_password"]}
          value={m1_password}
          onChange={(e) => {
            set("m1_password", e.target.value);
          }}
        />
        <Text title="Retype Password" element="h4" region="label5" />
        <PasswordInput
          region="input5"
          value={m1_confirmPassword}
          onChange={(e) => {
            set("m1_confirmPassword", e.target.value);
          }}
        />

        <Text title="Phone" element="h4" region="label6" />
        <NumberInput
          region="input6"
          value={m1_phone}
          onChange={(e) => {
            set("m1_phone", e.target.value);
          }}
          placeholder="1234567890"
        />
        <Text title="Pay" element="h4" region="label7" />
        <NumberInput
          region="input7"
          value={m1_pay}
          required={true}
          onChange={(e) => {
            set("m1_pay", e.target.value);
          }}
          error={state.error["m1_pay"]}
        />

        <Text title="Roles" element="h4" region="label8" />
        <DropDown
          region="input8"
          title={
            m1_role === ""
              ? "Roles"
              : m1_role.charAt(0).toUpperCase() + m1_role.slice(1)
          }
          value={m1_role}
          required={true}
          error={state.error["m1_role"]}
          render={m1_render}
        >
          <DropDown.Options
            className="dropdown-option"
            onClick={() => {
              set("m1_role", "production");
            }}
          >
            Production
          </DropDown.Options>
          <DropDown.Options
            className="dropdown-option"
            onClick={() => {
              set("m1_role", "manager");
            }}
          >
            Manager
          </DropDown.Options>
          <DropDown.Options
            className="dropdown-option"
            onClick={() => {
              set("m1_role", "shipping");
            }}
          >
            Shipping
          </DropDown.Options>
        </DropDown>

        <ButtonPrimary
          region="button"
          style={{ justifySelf: "end" }}
          title="Submit"
          onClick={async (e) => {
            handleOnSubmit(e);
          }}
        />
      </Card>
    </ErrorBox>
  );
}
